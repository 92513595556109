import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.3.0";

// pages for this product
import AboutUsPage from "views/AboutUsPage/AboutUsPage.jsx";
import CoronaResourcesPage from "views/CoronaResourcesPage/CoronaResourcesPage.jsx";
import BlogPostPage from "views/BlogPostPage/BlogPostPage.jsx";
import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.jsx";
import ComponentsPage from "views/ComponentsPage/ComponentsPage.jsx";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.jsx";
import EcommercePage from "views/EcommercePage/EcommercePage.jsx";
import LandingPage from "views/LandingPage/LandingPage.jsx";
import LoginPage from "views/LoginPage/LoginPage.jsx";
// import PresentationPage from "views/PresentationPage/PresentationPage.jsx";
import PricingPage from "views/PricingPage/PricingPage.jsx";
import ProfilePage from "views/ProfilePage/ProfilePage.jsx";
// import ProductPage from "views/ProductPage/ProductPage.jsx";
import SectionsPage from "views/SectionsPage/SectionsPage.jsx";
import ShoppingCartPage from "views/ShoppingCartPage/ShoppingCartPage.jsx";
import SignupPage from "views/SignupPage/SignupPage.jsx";
import ErrorPage from "views/ErrorPage/ErrorPage.jsx";
import CommunicationStrategiesPage from "views/ProductPage/CommunicationStrategiesPage/CommunicationStrategiesPage.jsx";
import PresentationSkillsPage from "views/ProductPage/PresentationSkillsPage/PresentationSkillsPage.jsx";
import ProfessionalBrandPage from "views/ProductPage/ProfessionalBrandPage/ProfessionalBrandPage.jsx";
import UniversityPage from "views/UniversityPage/UniversityPage.jsx";
import LocalGovernmentPage from "views/LocalGovernmentPage/LocalGovernmentPage.jsx";
import CoursesPage from "views/CoursesPage/CoursesPage.jsx";
import IntroductionLeadershipPage from "./views/ProductPage/IntroductionLeadershipPage/IntroductionLeadershipPage.jsx";
import CivicLeadershipPage from "./views/ProductPage/CivicLeadershipPage/CivicLeadershipPage.jsx";
import EventPlanningPage from "./views/ProductPage/EventPlanningPage/EventPlanningPage.jsx";
import EventMarketingPage from "./views/ProductPage/EventMarketingPage/EventMarketingPage.jsx";
import MeetingManagementPage from "./views/ProductPage/MeetingManagementPage/MeetingManagementPage.jsx";
import GlobalAmbassadorPage from "./views/ProductPage/GlobalAmbassadorPage/GlobalAmbassadorPage.jsx";
import ConflictResolutionPage from "./views/ProductPage/ConflictResolutionPage/ConflictResolutionPage.jsx";
import TeamworkPage from "./views/ProductPage/TeamworkPage/TeamworkPage.jsx";
import NetworkingPage from "./views/ProductPage/NetworkingPage/NetworkingPage.jsx";
import OrientationsUtsPage from "./views/ProductPage/OrientationsUtsPage/OrientationsUtsPage.jsx";
import OrientationsWsuPage from "./views/ProductPage/OrientationsWsuPage/OrientationsWsuPage.jsx";
import OrientationsAcuPage from "./views/ProductPage/OrientationsAcuPage/OrientationsAcuPage.jsx";
import LocalGovernmentRydeStrategyPage from "./views/ProductPage/LocalGovernmentRydeStrategyPage/LocalGovernmentRydeStrategyPage.jsx";
import LocalGovernmentDarwinStrategyPage from "./views/ProductPage/LocalGovernmentDarwinStrategyPage/LocalGovernmentDarwinStrategyPage.jsx";
import VerifyEmailPage from "views/VerifyEmailPage/VerifyEmailPage.jsx";
import ForgetPasswordPage from "views/ForgetPasswordPage/ForgetPasswordPage.jsx";
import ResetPasswordPage from "views/ResetPasswordPage/ResetPasswordPage.jsx";

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

var hist = createBrowserHistory();

ReactDOM.render(
  < >
  <ReactNotification />
  <Router history={hist}>
    <Switch>
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/blog-post" component={BlogPostPage} />
      <Route path="/blog-posts" component={BlogPostsPage} />
      <Route path="/components" component={ComponentsPage} />
      <Route path="/contact-us" component={ContactUsPage} />
      <Route path="/ecommerce-page" component={EcommercePage} />
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/pricing" component={PricingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      {/* <Route exact path="/product-page" component={ProductPage} /> */}
      <Route path="/sections" component={SectionsPage} />
      <Route path="/shopping-cart-page" component={ShoppingCartPage} />
      <Route path="/signup-page" component={SignupPage} />
      <Route path="/error-page" component={ErrorPage} />
      <Route path="/universities" component={UniversityPage} />
      <Route path="/lga" component={LocalGovernmentPage} />
      <Route path="/courses" component={CoursesPage} />
      <Route exact path="/corona-resources" component={CoronaResourcesPage} />
      <Route
        exact
        path="/product-page/communication-strategies-page"
        component={CommunicationStrategiesPage}
      />
      <Route
        exact
        path="/product-page/presentation-skills-page"
        component={PresentationSkillsPage}
      />
      <Route
        exact
        path="/product-page/professional-brand-page"
        component={ProfessionalBrandPage}
      />
      <Route
        exact
        path="/product-page/leadership-introduction-page"
        component={IntroductionLeadershipPage}
      />
      <Route
        exact
        path="/product-page/civic-leadership-page"
        component={CivicLeadershipPage}
      />
      <Route
        exact
        path="/product-page/global-ambassador-page"
        component={GlobalAmbassadorPage}
      />
      <Route
        exact
        path="/product-page/conflict-resolution-page"
        component={ConflictResolutionPage}
      />
      <Route
        exact
        path="/product-page/teamwork-page"
        component={TeamworkPage}
      />
      <Route
        exact
        path="/product-page/networking-page"
        component={NetworkingPage}
      />
      <Route
        exact
        path="/product-page/event-planning-page"
        component={EventPlanningPage}
      />
      <Route
        exact
        path="/product-page/event-marketing-page"
        component={EventMarketingPage}
      />
      <Route
        exact
        path="/product-page/meeting-management-page"
        component={MeetingManagementPage}
      />
      <Route
        exact
        path="/product-page/orientation-uts-page"
        component={OrientationsUtsPage}
      />
      <Route
        exact
        path="/product-page/orientation-wsu-page"
        component={OrientationsWsuPage}
      />
      <Route
        exact
        path="/product-page/orientation-acu-page"
        component={OrientationsAcuPage}
      />
      <Route
        exact
        path="/product-page/youth-strategy-ryde"
        component={LocalGovernmentRydeStrategyPage}
      />
      <Route
        exact
        path="/product-page/youth-strategy-darwin"
        component={LocalGovernmentDarwinStrategyPage}
      />
      {/* <Route path="/" component={PresentationPage} /> */}
      <Route path="/verify-email" component={VerifyEmailPage} />
      <Route path="/forget-password" component={ForgetPasswordPage} />
      <Route path="/reset-password/:token" component={ResetPasswordPage} />

      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>
  </>,
  document.getElementById("root")
);
