/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";

class SectionUniversityProductEventPlanning extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className="cd-section" {...rest}>
        <div className={classes.projects}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
              ></GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/event-planning-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/Courses_EventPlanning-min.jpg"
                        alt="Event Planning course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/event-planning-page">
                      <h4 className={classes.cardTitle}>Event Planning</h4>
                    </Link>
                    <h6 className={classes.description}>
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      Brainstorm event and project ideas. Do an event reality
                      check. Plan out your event and learn skills such as risk
                      planning and budgetting.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/event-marketing-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_eventMarketing.jpg"
                        alt="Event Marketing course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/event-marketing-page">
                      <h4 className={classes.cardTitle}>Event Marketing</h4>
                    </Link>
                    <h6 className={classes.description}>
                      {" "}
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      This course will enable you to create a marketing plan and
                      introduce you to guerilla marketing techniques. You will
                      also learn sponsorship skills.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/meeting-management-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_meeting_management-min.jpeg"
                        alt="Meeting Management course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/meeting-management-page">
                      <h4 className={classes.cardTitle}>Meeting Management</h4>
                    </Link>
                    <h6 className={classes.description}>
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      In this course, you will learn about key meeting roles and
                      responsibilities. You will understand how to create an
                      effective agenda and manage time. Learn also to work with
                      difficult personalities.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(projectsStyle)(SectionUniversityProductEventPlanning);
