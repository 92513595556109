import React from "react";

// core components
import NavPills from "components/NavPills/NavPills.jsx";
import PropTypes from "prop-types";
import SectionUniversityProductCommunication from "./SectionUniversityProductCommunication.jsx";
import SectionUniversityProductLeadership from "./SectionUniversityProductLeadership.jsx";
import SectionUniversityProductOrientations from "./SectionUniversityProductOrientations.jsx";
import SectionUniversityProductEmployability from "./SectionUniversityProductEmployability.jsx";
import SectionUniversityProductEventPlanning from "./SectionUniversityProductEventPlanning";

NavPills.defaultProps = {
  active: 0,
  color: "danger"
};

NavPills.propTypes = {
  // index of the default active pill
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.string,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object
  }),
  alignCenter: PropTypes.bool
};

class SectionUniversityNavPills extends React.Component {
  render() {
    return (
      <NavPills
        color="danger"
        tabs={[
          {
            tabButton: "Communication",
            tabContent: <SectionUniversityProductCommunication />
          },
          {
            tabButton: "Leadership",
            tabContent: (
              <span>
                <div>
                  <SectionUniversityProductLeadership />
                </div>
              </span>
            )
          },
          {
            tabButton: "Employability",
            tabContent: (
              <span>
                <div>
                  <SectionUniversityProductEmployability />
                </div>
              </span>
            )
          },
          {
            tabButton: "Event & Project Planning",
            tabContent: (
              <span>
                <div>
                  <SectionUniversityProductEventPlanning />
                </div>
              </span>
            )
          },
          {
            tabButton: "Student Orientations",
            tabContent: (
              <span>
                <div>
                  <SectionUniversityProductOrientations />
                </div>
              </span>
            )
          }
        ]}
      />
    );
  }
}

export default SectionUniversityNavPills;
