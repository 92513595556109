import React from "react";
import { Link } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Footer from "components/Footer/Footer.jsx";

import city from "assets/img/ContactUs.jpeg";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.jsx";

class ContactUsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(1, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
   
        <div
          className={`${classes.contacts}`}
          style={{
            backgroundImage: `url(${city})`,
            height: "200px"
          }}
        >
          <div className={classes.container}>
            <GridContainer />
          </div>
        </div>
        {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Please Verify Your Email</h2>

          </div>
        </div>
     
      </div>
    );
  }
}

export default withStyles(contactUsStyle)(ContactUsPage);
