/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";

class SectionUniversityProductLeadership extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className="cd-section" {...rest}>
        <div className={classes.projects}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
              >
                <h2 className={classes.title}>Impactful Leadership Courses</h2>
                <h5 className={classes.description}>
                  We all have what it takes to be leaders.
                </h5>
                <div className={classes.sectionSpace} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/leadership-introduction-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_leadership-min.jpg"
                        alt="Introduction to Leadership course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/leadership-introduction-page">
                      <h4 className={classes.cardTitle}>
                        Leadership & Influence
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      Learn the different types of leadership & get introduced
                      to Situational Leadership. You explore your personal
                      leadership inventory and understand the 5 key elements of
                      leadership. Get introduced to the art of influence
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/civic-leadership-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_civic_leadership.jpg"
                        alt="Civic Leadership course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/civic-leadership-page">
                      <h4 className={classes.cardTitle}>
                        Civic Leadership & Design Thinking
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      {" "}
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      This course will enable you to action your social ideas in
                      the true spirit of civic leadership. You apply key design
                      thinking skills in a design sprint including ideation. You
                      complete the workshop with an exciting pitch of your idea.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/global-ambassador-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_global_ambassador-min.jpg"
                        alt="Global Ambassador course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/global-ambassador-page">
                      <h4 className={classes.cardTitle}>Global Ambassador</h4>
                    </Link>
                    <h6 className={classes.description}>
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      Build your global ambassadorship skills and develop an
                      international mindset. Assess yourself against key global
                      capabilities and build key cultural competency skills.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(projectsStyle)(SectionUniversityProductLeadership);
