import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";

// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Info from "components/Typography/Info.jsx";
import Danger from "components/Typography/Danger.jsx";
import Success from "components/Typography/Success.jsx";
import Button from "components/CustomButtons/Button.jsx";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.jsx";

import cardBlog4 from "assets/img/examples/card-blog4.jpg";
import office2 from "assets/img/office2.jpg";

function CoronaResourcesPage({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div>
      <Header
        brand="Home"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="black"
        changeColorOnScroll={{
          height: 300,
          color: "info"
        }}
      />
      <div className="cd-section" {...rest}>
        {/* Blogs 1 START */}
        <div className={classes.blog}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={10}
                md={10}
                className={`${classes.mlAuto} ${classes.mrAuto}`}
              >
                <h2 className={classes.title}>
                  Free Resources To Thrive in a Corona World
                </h2>
                <br />
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={5} md={5}>
                      <CardHeader image plain>
                        <a href="#pablito" onClick={e => e.preventDefault()}>
                          <img
                            src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/CoronaResourcesPositive.jpg"
                            alt="..."
                          />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${cardBlog4})`,
                            opacity: "1"
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={7} md={7}>
                      <Info>
                        <h6 className={classes.cardCategory}>
                          ONLINE TRAINING
                        </h6>
                      </Info>
                      <h3 className={classes.cardTitle}>
                        Session 1: Tips to Manage "The Overwhelm"
                      </h3>
                      <h5 className={classes.description1}>
                        FREE 30 minute online training to manage the new world
                        we have been thrust into.This is a chance to;
                        <ul>
                          <li>
                            Connect and share with others about this chaotic
                            experience
                          </li>
                          <li>Learn and apply meditation techniques</li>
                          <li>
                            Learn small practical tips to help calm your mind{" "}
                          </li>
                        </ul>
                        <h4 className={classes.cardTitle}>Details</h4>
                        <ul>
                          <li>
                            <strong>DATE:</strong>Thursday, 26th March 2020
                          </li>
                          <li>
                            <strong>TIME:</strong>11.00am-11.30am
                          </li>
                          <li>
                            <strong>PRESENTER:</strong> Sally Hetherington{" "}
                          </li>
                        </ul>
                      </h5>
                      <a
                        href="https://zoom.us/meeting/register/tZ0tcuqqpzgvov-azoObNaWKOzwuvFidTw"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button round color="danger">
                          Click Here for Registration Link
                        </Button>
                      </a>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={7} md={7}>
                      <Danger>
                        <h6 className={classes.cardCategory}>
                          <TrendingUp />
                          ONLINE TRAINING
                        </h6>
                      </Danger>
                      <h3 className={classes.cardTitle}>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          Session 2: Create An Effective Lockdown/Self-Isolation
                          Routine
                        </a>
                      </h3>
                      <h5 className={classes.description1}>
                        FREE 30 minute online training to learn about how to
                        create a routine during self isolation and lockdown
                        periods.This is a chance to;
                        <ul>
                          <li>
                            Connect and share with others about this chaotic
                            experience and hear what others in self-isolation
                            are doing
                          </li>
                          <li>Learn easy exercise routines</li>
                          <li>Learn how to cultivate hobbies and passions </li>
                        </ul>
                        <h4 className={classes.cardTitle}>Details</h4>
                        <ul>
                          <li>
                            <strong>DATE:</strong> Friday, 27th March 2020
                          </li>
                          <li>
                            <strong>TIME:</strong> 11.00-11.30am
                          </li>
                          <li>
                            <strong>PRESENTER:</strong> Natasha Munasinghe (who
                            just experienced self-isolation){" "}
                          </li>
                        </ul>
                      </h5>
                      <a
                        href="https://zoom.us/meeting/register/tZwkf--srDgo1eBkpXZFBgA6sbojflgTtg"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button round color="danger">
                          Click Here for Registration Link
                        </Button>
                      </a>
                    </GridItem>
                    <GridItem xs={12} sm={5} md={5}>
                      <CardHeader image plain>
                        <img src={office2} alt="..." />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        {/* Blogs 1 END */}
      </div>
    </div>
  );
}

export default withStyles(blogsStyle)(CoronaResourcesPage);
