import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.jsx";

function SectionTestimonialsLocalGovernmentPage({ ...props }) {
  const { classes, ...rest } = props;

  return (
    <div className="cd-section" {...rest}>
      {/* Testimonials 1 START */}
      <div
        className={`${classes.testimonials} ${classes.sectionImage}`}
        style={{
          backgroundImage: `url("https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/testimonials_background.jpg")`
        }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
            >
              <h2 className={classes.title}>Client Love</h2>
              <h5 className={classes.description}>
                You are our priority. Here is what a few of our clients have
                said...
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    The training provided by the Frank team is very effective
                    for our new committee members. Adequate mix of talking and
                    activities. Resources are excellent:)
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Barbara Clifford</h4>
                  <h6 className={classes.cardCategory}>
                    Mildura Rural Council
                  </h6>
                  <CardAvatar testimonial testimonialFooter>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/lga_logos_mildura.jpeg"
                      alt="Mildura Council FRANK Team testimonial"
                    />
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    The Frank Team were spot on! They delivered exactly what our
                    group needed and the content responded precisely to our
                    request for service. Our facilitator on the day was young,
                    energetic and engaged really well with the participants.
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Joe Banno</h4>
                  <h6 className={classes.cardCategory}>Inner West Council</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/lga_logos_innerWest.png"
                      alt="Inner West Council FRANK Team testimonial"
                    />
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    The session was really enjoyable because it was so
                    interactive.
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Liz Fiveash</h4>
                  <h6 className={classes.cardCategory}>
                    Queensland Government (National Parks Services & Recreation)
                  </h6>
                  <CardAvatar testimonial testimonialFooter>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/lga_logos_qldGovernment.png"
                      alt="Queensland Government FRANK Team testimonial"
                    />
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 1 END */}
    </div>
  );
}

export default withStyles(testimonialsStyle)(
  SectionTestimonialsLocalGovernmentPage
);
