import * as yup from 'yup';
import pick from 'lodash.pick';

const UserSchema = {
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required().min(3),
    mobileNumber: yup.number(),
}

export const getShema =(fields)=>{
    const requestedSchema = pick(UserSchema, fields);
    return yup.object().shape(requestedSchema);
}

