/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";

class SectionLocalGovernmentProductLeadership extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className="cd-section" {...rest}>
        <div className={classes.projects}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
              >
                <h2 className={classes.title}>
                  Youth Strategy & Planning Services
                </h2>
                <h5 className={classes.description}>
                  FRANK works with you to design and deliver youth
                  consultations, youth strategy reports and action plans. Here
                  are a few examples of our work...
                </h5>
                <div className={classes.sectionSpace} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/youth-strategy-ryde">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/lga_RydeYouthStrategy-min.jpg"
                        alt="City of Ryde Youth Strategy"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/youth-strategy-ryde">
                      <h4 className={classes.cardTitle}>
                        City of Ryde Council Youth Strategy & Action Plan
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      Design & Delivery of Youth Strategy
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      FRANK worked with Ryde Council to design and deliver their
                      4 year Youth Strategy and Action Plan. This included
                      delivering focus groups, surveys, pop-up consultation
                      stalls, internal Council and Councillor briefing sessions
                      and data analysis and reporting.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/youth-strategy-darwin">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/lga_youthStrategyDarwin-min.jpg"
                        alt="City of Darwin Youth Strategy"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/civic-leadership-page">
                      <h4 className={classes.cardTitle}>
                        City of Darwin Council Youth Strategy
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      {" "}
                      Delivery of Youth Strategy Consultations
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      FRANK designed and delivered consultations with service
                      providers and young people based on 6 identified core
                      themes, identified in the Youth Strategy survey. Data from
                      these consultations was compiled, analysed and shared with
                      Council to include in their Youth Strategy document.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(projectsStyle)(
  SectionLocalGovernmentProductLeadership
);
