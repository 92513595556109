import React from "react";
import { Link } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Footer from "components/Footer/Footer.jsx";

import city from "assets/img/ContactUs.jpeg";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.jsx";

class ContactUsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(1, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="info"
          brand="FRANK Education"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
          {...rest}
        />
        <div
          className={`${classes.contacts}`}
          style={{
            backgroundImage: `url(${city})`,
            height: "300px"
          }}
        >
          <div className={classes.container}>
            <GridContainer />
          </div>
        </div>
        {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Get in Touch</h2>
            <GridContainer>
              <GridItem md={6} sm={6}>
                <p>
                  Have some questions? We'd love to hear from you.
                  <br />
                </p>
                {/* <form>
                    <CustomInput
                      labelText="Your Name"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <CustomInput
                      labelText="Email address"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <CustomInput
                      labelText="Phone"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <CustomInput
                      labelText="Your message"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 6
                      }}
                    />
                    <div className={classes.textCenter}>
                      <Button color="primary" round>
                        Contact us
                      </Button>
                    </div>
                  </form> */}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <div className={classes.container}>
                <GridItem md={12} sm={12}>
                  <InfoArea
                    className={
                      (classes.info, classes.left, classes.inlineBlock)
                    }
                    title="Find us at the office"
                    description={
                      <p>
                        1/204 Clarence Street <br /> Sydney, <br /> NSW 2000
                      </p>
                    }
                    icon={PinDrop}
                    iconColor="info"
                    justifyContent="500px"
                  />
                </GridItem>
                <GridItem>
                  <InfoArea
                    className={(classes.left, classes.inlineBlock)}
                    title="Give us a ring"
                    description={
                      <p>
                        1300 953 133 <br /> Mon - Fri, 9:00am-5:00pm
                      </p>
                    }
                    icon={Phone}
                    iconColor="info"
                  />
                </GridItem>
                <GridItem>
                  <InfoArea
                    className={(classes.left, classes.inlineBlock)}
                    title="Email us"
                    description={<p>info@frankteam.com.au</p>}
                    icon={BusinessCenter}
                    iconColor="info"
                  />
                </GridItem>
              </div>
            </GridContainer>
          </div>
        </div>
        <Footer
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <Link to="/about-us" className={classes.block}>
                      About us
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link to="/universities" className={classes.block}>
                      Universities
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link to="/lga" className={classes.block}>
                      Local Governments
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link to="/courses" className={classes.block}>
                      Courses
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link to="/contact-us" className={classes.block}>
                      Contact Us
                    </Link>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()}
                {""}. Made with <Favorite className={classes.icon} /> by FRANK
                Education
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(contactUsStyle)(ContactUsPage);
