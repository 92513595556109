import React from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";

import image from "assets/img/bg7.jpg";
import { getShema } from '../../schema/user';
import * as userApi from '../../api/user';
import { MobileStepper } from "@material-ui/core";
import { store } from 'react-notifications-component';

class Components extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [1],
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      mobileNumber: '',
      error: {}
    };
    this.handleToggle = this.handleToggle.bind(this);
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  async validateInput(value, field) {
    const schema = getShema(field);
    const fieldName = field[0]
    try {
      await schema.validate({ [fieldName]: value })
      this.setState({ [fieldName]: value })
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          [fieldName]: null
        }
      }))
    } catch (err) {
      // debugger
      console.log("err", err)
      if(fieldName === "mobileNumber") err.errors[0] = "Invalid mobile number"
      if(fieldName === "mobileNumber" && err.params.originalValue === "") err.errors[0] = null

      this.setState(prevState => ({
        error: {
          ...prevState.error,
          [fieldName]: err.errors[0]
        }
      }))
    }
  }

  async handleChange() {
    // debugger
    const newUser = {
      email: this.state.email,
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      mobile: this.state.mobileNumber
    }

    try {
      await userApi.registerUser(newUser);
      this.props.history.push({ pathname: "/verify-email" })
    } catch (err) {
      console.log("err", err, "kjhjkhk", err.response.data)
      // debugger
      debugger
      const errorMsg = err.response.data ? err.response.data : 'Fail To Register, Please Try Again Later';
      const error = { ...this.state.error }
      error.register = errorMsg
      this.setState({ error })
    }
  }


  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="FRANK Education"
          links={<HeaderLinks dropdownHoverColor="rose" />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundColor: "#00acc1",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={10} md={10}>
                <Card className={classes.cardSignup}>
                  <h2 className={classes.cardTitle}>Register</h2>
                  {this.state.error.register ?
                    <div className={classes.cardError}> <h5 className={classes.cardErrorTitle}>{this.state.error.register}</h5></div>
                    : null
                  }
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={5} md={5}>
                        <form className={classes.form}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            inputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Face
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "First Name...",
                              onChange: (event) => this.validateInput(event.target.value, ['firstName'])
                            }}
                            error={this.state.error.firstName || null}
                          />
                          {this.state.error.firstName ?
                            <p className={classes.fontError}>{this.state.error.firstName}</p> :
                            null}
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            inputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Face
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "Last Name...",
                              onChange: (event) => this.validateInput(event.target.value, ['lastName'])
                            }}
                            error={this.state.error.lastName || null}
                          />
                          {this.state.error.lastName ?
                            <p className={classes.fontError}>{this.state.error.lastName}</p> :
                            null}
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            inputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                  </Icon>
                                </InputAdornment>
                              ),
                              type: "password",
                              placeholder: "Password...",
                              onChange: (event) => this.validateInput(event.target.value, ['password'])
                            }}
                            error={this.state.error.password || null}
                          />
                          {this.state.error.password ?
                            <p className={classes.fontError}>{this.state.error.password}</p> :
                            null}
                        </form>
                      </GridItem>
                      <GridItem xs={12} sm={5} md={5}>
                        {/* <div className={classes.textCenter}>
                          <Button justIcon round color="twitter">
                            <i
                              className={classes.socials + " fab fa-twitter"}
                            />
                          </Button>
                          {` `}
                          <Button justIcon round color="dribbble">
                            <i
                              className={classes.socials + " fab fa-dribbble"}
                            />
                          </Button>
                          {` `}
                          <Button justIcon round color="facebook">
                            <i
                              className={classes.socials + " fab fa-facebook-f"}
                            />
                          </Button>
                          {` `}
                          <h4 className={classes.socialTitle}>
                            or be classical
                          </h4>
                        </div> */}
                        <form className={classes.form}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            inputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Email
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              type: "email",
                              placeholder: "Email...",
                              onChange: (event) => this.validateInput(event.target.value, ['email'])
                            }}
                            error={this.state.error.email || null}
                          />
                            {this.state.error.email ?
                            <p className={classes.fontError}>{this.state.error.email}</p> :
                            null}
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            inputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <MobileStepper className={classes.inputAdornmentIcon}></MobileStepper>
                                </InputAdornment>
                              ),
                              type: "mobile",
                              placeholder: "Mobile Number...",
                              onChange: (event) => this.validateInput(event.target.value, ['mobileNumber'])
                            }}
                            error={this.state.error.mobileNumber || null}
                          />
                            {this.state.error.mobileNumber ?
                            <p className={classes.fontError}>{this.state.error.mobileNumber}</p> :
                            null}
                          <FormControlLabel
                            classes={{
                              label: classes.label
                            }}
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => this.handleToggle(1)}
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                                checked={
                                  this.state.checked.indexOf(1) !== -1
                                    ? true
                                    : false
                                }
                              />
                            }
                            label={
                              <span>
                                I agree to the{" "}
                                <a href="#pablo">terms and conditions</a>.
                              </span>
                            }
                          />
                          <div className={classes.textCenter}>
                            <Button round color="customButtonColor" onClick={this.handleChange.bind(this)} disabled={this.state.disabled}>
                              Get started
                            </Button>
                          </div>
                        </form>
                        <div className={classes.textCenter}>
                          <span>
                            Already have an account?{" "}
                            <Link to="/login-page"> Login </Link>
                          </span>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer
            content={
              <div>
                <div className={classes.left}>
                  <List className={classes.list}>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/about-us" className={classes.block}>
                        About us
                    </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/universities" className={classes.block}>
                        Universities
                    </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/lga" className={classes.block}>
                        Local Governments
                    </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/courses" className={classes.block}>
                        Courses
                    </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/contact-us" className={classes.block}>
                        Contact Us
                    </Link>
                    </ListItem>
                  </List>
                </div>
                <div className={classes.right}>
                  &copy; {1900 + new Date().getYear()}
                  {""}. Made with <Favorite className={classes.icon} /> by FRANK
                Education
              </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default withStyles(signupPageStyle)(Components);
