import React from "react";
import { Link } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
// sections for this page
import SectionTestimonialsOrientation from "./Sections/SectionTestimonialsOrientation";
import SectionUniversityFeaturesClients from "./Sections/SectionUniversityFeaturesClients";
import SectionOrientationFeatures from "./Sections/SectionOrientationFeatures";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.jsx";

class OrientationsUtsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          brand="FRANK Education"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
        />
        <Parallax
          image="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_orientations_uts-min.jpeg"
          filter="light"
          small
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              />
              {/* <h1 className={classes.title}>UTS Orientations</h1> */}
              {/* <h4>Case Study 1</h4> */}
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <SectionOrientationFeatures />
          </div>
          <div className={classes.container}>
            <SectionUniversityFeaturesClients />
          </div>
        </div>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <SectionTestimonialsOrientation />
        </div>
        <Footer
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <Link to="/about-us" className={classes.block}>
                      About us
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link to="/universities" className={classes.block}>
                      Universities
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link to="/lga" className={classes.block}>
                      Local Governments
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link to="/courses" className={classes.block}>
                      Courses
                    </Link>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Link to="/contact-us" className={classes.block}>
                      Contact Us
                    </Link>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()}.{""} Made with{" "}
                <Favorite className={classes.icon} /> by FRANK Education
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(aboutUsStyle)(OrientationsUtsPage);

//future version of Orientation Page
//needs button config
// import React from "react";
// import { Link } from "react-router-dom";
// // nodejs library that concatenates classes
// import classNames from "classnames";
// // @material-ui/core components
// import withStyles from "@material-ui/core/styles/withStyles";

// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// // @material-ui/icons
// import LocalShipping from "@material-ui/icons/LocalShipping";
// import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import Favorite from "@material-ui/icons/Favorite";
// // core components
// import Header from "components/Header/Header.jsx";
// import HeaderLinks from "components/Header/HeaderLinks.jsx";
// import Parallax from "components/Parallax/Parallax.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";
// import Footer from "components/Footer/Footer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
// import Accordion from "components/Accordion/Accordion.jsx";
// import InfoArea from "components/InfoArea/InfoArea.jsx";

// //core sections
// import SectionProductNatasha from "./Sections/SectionProductNatasha.jsx";
// import SectionTestimonialsOrientation from "./Sections/SectionTestimonialsOrientation.jsx";

// //import styling
// import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.jsx";
// import Fab from "@material-ui/core/Fab";
// import NavigationIcon from "@material-ui/icons/Navigation";

// class OrientationsUtsPage extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       colorSelect: "0",
//       sizeSelect: "0"
//     };
//   }
//   handleSelect = event => {
//     this.setState({ [event.target.name]: event.target.value });
//   };
//   componentDidMount() {
//     window.scrollTo(0, 0);
//     document.body.scrollTop = 0;
//   }

//   render() {
//     const { classes } = this.props;

//     return (
//       <div className={classes.productPage}>
//         <Header
//           brand="FRANK Team Education"
//           links={<HeaderLinks dropdownHoverColor="rose" />}
//           fixed
//           color="transparent"
//           changeColorOnScroll={{
//             height: 100,
//             color: "info"
//           }}
//         />
//         <Parallax
//           img
//           src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_communication.jpeg"
//           filter="info"
//           className={classes.pageHeader}
//         />
//         <div className={classNames(classes.section, classes.sectionGray)}>
//           <div className={classes.container}>
//             <div className={classNames(classes.main, classes.mainRaised)}>
//               <GridContainer>
//                 <GridItem md={6} sm={6}>
//                   <Parallax
//                     image="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/University_showreel_thumbnail.png"
//                     small
//                     end="38%"
//                   >
//                     <Fab
//                       className={classes.buttonStyle}
//                       variant="extended"
//                       aria-label="Delete"
//                       href="https://www.youtube.com/watch?v=JiZyVsSwUHs&t=2s"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <NavigationIcon />
//                       Let's see the video
//                     </Fab>
//                   </Parallax>
//                 </GridItem>
//                 <GridItem md={6} sm={6}>
//                   <h2 className={classes.title}>Present like a TED Speaker</h2>
//                   <h3 className={classes.title}>Face to Face Training</h3>
//                   <Accordion
//                     active={0}
//                     activeColor="info"
//                     collapses={[
//                       {
//                         title: "What Will I Learn?",
//                         content: (
//                           <ul>
//                             <li>
//                               How to understand your audience & their needs{" "}
//                             </li>
//                             <li>
//                               Learn public speaking tips including delivery tips
//                             </li>
//                             <li>Public speaking practice activity</li>
//                             <li>How to overcome public speaking nerves</li>
//                           </ul>
//                         )
//                       },
//                       {
//                         title: "Course Information",
//                         content: (
//                           <ul>
//                             <li>
//                               Workshops are engaging and interactive. We do not
//                               do death by Powerpoint.
//                             </li>
//                             <li>Workshop duration options;</li>
//                             <ul>
//                               <li>Power Up Workshop - 1 hour</li>
//                               <li>Merit Workshop - 2 hours</li>
//                               <li>Transformation Workshop - 3 hours</li>
//                             </ul>
//                             <li>
//                               <strong>Our Recommendation:</strong> Combine this
//                               workshop with the{" "}
//                               <Link to="/product-page/communication-strategies-page">
//                                 Communication Strategies{" "}
//                               </Link>{" "}
//                               training program for a full day (6 hour) Immersive
//                               Workshop.
//                             </li>
//                             <li>
//                               Facilitators available in NSW, VIC, QLD & SA
//                             </li>
//                             <li>
//                               Audience numbers can be from 10-1000 participants
//                             </li>
//                             <li>
//                               Custom programs are available for an additional
//                               cost
//                             </li>
//                           </ul>
//                         )
//                       },
//                       {
//                         title: "Recommended Audience for Course",
//                         content: (
//                           <ul>
//                             <li>University students</li>
//                             <li>Young professionals and graduates</li>
//                             <li>Young people in local government</li>
//                             <li>Young people in community organisations</li>
//                           </ul>
//                         )
//                       },
//                       {
//                         title: "Free Inclusions",
//                         content: (
//                           <ul>
//                             <li>
//                               Project Manager to understand your needs and work
//                               with you throughout the booking{" "}
//                             </li>
//                             <li>
//                               Materials and resources for games and activities
//                             </li>
//                             <li>Reporting and evaluation</li>
//                           </ul>
//                         )
//                       },
//                       {
//                         title: "Course Guide",
//                         content: (
//                           <p>
//                             Download the Presentation like a TED Speaker Course
//                             Guide here
//                           </p>
//                         )
//                       }
//                     ]}
//                   />

//                   <GridContainer className={classes.pullRight}>
//                     <Link to="/contact-us">
//                       <Button round color="danger">
//                         Contact Us
//                       </Button>
//                     </Link>
//                   </GridContainer>
//                 </GridItem>
//               </GridContainer>
//             </div>
//             <div className={classNames(classes.features, classes.textCenter)}>
//               <GridContainer>
//                 <GridItem md={4} sm={4}>
//                   <InfoArea
//                     title="Engaging and Interactive"
//                     description="We combine training with games, activities & practical application."
//                     icon={LocalShipping}
//                     iconColor="info"
//                     vertical
//                   />
//                 </GridItem>
//                 <GridItem md={4} sm={4}>
//                   <InfoArea
//                     title="Refundable Policy"
//                     description="100% refundable if you are not happy with the training."
//                     icon={VerifiedUser}
//                     iconColor="success"
//                     vertical
//                   />
//                 </GridItem>
//                 <GridItem md={4} sm={4}>
//                   <InfoArea
//                     title="Popular Course"
//                     description="You are in good company; this is a super popular course."
//                     icon={Favorite}
//                     iconColor="info"
//                     vertical
//                   />
//                 </GridItem>
//               </GridContainer>
//             </div>
//           </div>
//         </div>
//         <div>
//           <SectionProductNatasha />
//           <SectionTestimonialsOrientation />
//         </div>
//         <Footer
//           content={
//             <div>
//               <div className={classes.left}>
//                 <List className={classes.list}>
//                   <ListItem className={classes.inlineBlock}>
//                     <Link to="/courses" className={classes.block}>
//                       Courses
//                     </Link>
//                   </ListItem>
//                   <ListItem className={classes.inlineBlock}>
//                     <Link to="/about-us" className={classes.block}>
//                       About us
//                     </Link>
//                   </ListItem>
//                   <ListItem className={classes.inlineBlock}>
//                     <Link to="/blog-posts" className={classes.block}>
//                       {" "}
//                       Blog
//                     </Link>
//                   </ListItem>
//                   <ListItem className={classes.inlineBlock}>
//                     <Link to="/contact-us" className={classes.block}>
//                       Contact Us
//                     </Link>
//                   </ListItem>
//                 </List>
//               </div>
//               <div className={classes.right}>
//                 &copy; {1900 + new Date().getYear()} made with{" "}
//                 <Favorite className={classes.icon} /> by{" "}
//                 <Link to="/about-us">Natasha @ FRANK</Link> as she is a slight
//                 nerd in her spare time.
//               </div>
//             </div>
//           }
//         />
//       </div>
//     );
//   }
// }

// export default withStyles(productStyle)(OrientationsUtsPage);
