import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GroupWork from "@material-ui/icons/GroupWork";
import Extension from "@material-ui/icons/Extension";
import WatchLater from "@material-ui/icons/WatchLater";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.jsx";

function SectionUniversityFeatures({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto}`}
            >
              <h2 className={classes.title}>
                We deliver engaged learning. Every time.
              </h2>
              <h5 className={classes.description}>
                Higher education students require engaged and interactive
                learning - both online and offline. Talk to us about we can
                support you in your efforts to prepare the emerging generations
                for the future of work
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Chat}
                title="Training for Employability & Work Integrated Learning as well as for Extra & Co-Curricular"
                description="Empower your students for the 21st Century Future of Work with our proven face-to-face training programs"
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={VerifiedUser}
                title="Trusted by over 13 Universities"
                description="Our experience in the higher education sector allows us to deliver the best training programs and insights"
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Fingerprint}
                title="Customised and scalable online video training"
                description="Learn more about effective online bite-sized video training programs. We work with you to design and deliver learning experiences that meet your objectives"
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 1 END */}

        {/* Feature 3 START */}
        <div className={classes.features3}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto} ${
                classes.textCenter
              }`}
            >
              <h2 className={classes.title}>Our training programs</h2>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <a
                href="https://www.youtube.com/watch?v=JiZyVsSwUHs&t=2s"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={classes.phoneContainer}>
                  <img
                    src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/University_showreel_thumbnail.png"
                    alt="FRANK Team Interchange video"
                  />
                </div>
              </a>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <InfoArea
                className={classes.infoArea}
                icon={Extension}
                title="Comprehensive training"
                description="We cover a wide range of training topics from employability & entrepreneurship to civic leadership & innovation. We can customise the learning experience for your students"
                iconColor="info"
              />
              <InfoArea
                className={classes.infoArea}
                icon={GroupWork}
                title="All audiences"
                description="Whether your audience is 10 or a 1000 participants; we have the expertise to build engaging learning experiences. We understand the unique needs of undergraduate, postgraduate, mature age and international students. We realise there is a difference between the needs of study abroad/exchange, equity, first-in-family and indigenous students"
                iconColor="info"
              />
              <InfoArea
                className={classes.infoArea}
                icon={WatchLater}
                title="Flexible timing"
                description="Training programs include 1 hour Power Up workshops to multi-day training sessions. We have flexibility to work with your objectives and budget"
                iconColor="info"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withStyles(featuresStyle)(SectionUniversityFeatures);
