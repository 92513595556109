/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";

class SectionLocalGovernmentFeaturesClients extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className="cd-section" {...rest}>
        <div className={classes.projects}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
              >
                <h2 className={classes.title}>
                  Clients FRANK has partnered with
                </h2>
                <div className={classes.sectionSpace} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/lga_logos_moreland.jpeg"
                      alt="FRANK Team Moreland Council client logo"
                    />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/lga_logos_liverpool.jpeg"
                      alt="FRANK Team Liverpool Council client logo"
                    />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/lga_logos_blacktown.jpeg"
                      alt="FRANK Team Blacktown Council client logo"
                    />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/lga_logos_ryde.jpg"
                      alt="FRANK Team Ryde Council client logo"
                    />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/lga_logos_mitchell.jpeg"
                      alt="FRANK Team Mitchell Shire client logo"
                    />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/lga_logos_innerWest.png"
                      alt="FRANK Team Innerwest Council client logo"
                    />
                    <br />
                  </CardHeader>
                </Card>
              </GridItem>
            </GridContainer>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(projectsStyle)(SectionLocalGovernmentFeaturesClients);
