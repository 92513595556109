/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";

class SectionUniversityFeaturesClients extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className="cd-section" {...rest}>
        <div className={classes.projects}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
              >
                <h2 className={classes.title}>
                  Clients FRANK has partnered with
                </h2>
                <div className={classes.sectionSpace} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/testimonial_sydneyuni.jpeg"
                      alt="FRANK Team sydney university client logo"
                    />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/university_UONLogo.jpeg"
                      alt="FRANK Team university of newcastle client logo"
                    />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/testimonials_university_uts.jpeg"
                      alt="FRANK Team university of technology sydney client logo"
                    />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/university_DeakinLogo.png"
                      alt="FRANK Team deakin university client logo"
                    />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/University_WSULogo.jpeg"
                      alt="FRANK Team western sydney university client logo"
                    />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={6} sm={4} md={2}>
                <Card plain className={classes.cardUniversityFeatures}>
                  <CardHeader image plain>
                    <img
                      src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/university_MelbourneUniLogo.png"
                      alt="FRANK Team melbourne university client logo"
                    />
                    <br />
                  </CardHeader>
                </Card>
              </GridItem>
            </GridContainer>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(projectsStyle)(SectionUniversityFeaturesClients);
