import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  headers: {
    Authorization: localStorage.getItem('usertoken'),
  },
});

instance.interceptors.request.use((config) => {
  if (!config.headers.Authorization) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token || '';
  }
  return config;
});

export default instance;
