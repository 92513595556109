/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";

class SectionUniversityProductEmployability extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className="cd-section" {...rest}>
        <div className={classes.projects}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
              >
                <h2 className={classes.title}>
                  In-Demand Employability Courses
                </h2>
                <h5 className={classes.description}>
                  The future of work is rapid, agile & full of opportunity
                </h5>
                <div className={classes.sectionSpace} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/professional-brand-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_brand-min.jpeg"
                        alt="Professional Brand course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/professional-brand-page">
                      <h4 className={classes.cardTitle}>
                        Professional Brand: Market Yourself to Employers
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      {" "}
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      To stand out and be noticed, you need demonstrate your
                      strengths and work. In this course, we show you how to
                      take all the awesome work you do - and own it proudly and
                      authentically. Get the attentions of employers, recruiters
                      and headhunters.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/teamwork-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_teamwork.jpg"
                        alt="Teamwork & Teambuilding course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/teamwork-page">
                      <h4 className={classes.cardTitle}>
                        Teamwork & Teambuilding
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      {" "}
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      This course will inform you of what it takes to build an
                      effective team. You will identify your team profile
                      personality. You will be introduced the 4 Tuckman stages
                      every team goes through. Learn problem solving techniques
                      to use as a team to help come to decisions
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/networking-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_networking.jpg"
                        alt="Networking course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/networking-page">
                      <h4 className={classes.cardTitle}>Networking</h4>
                    </Link>
                    <h6 className={classes.description}>
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      Learn about the benefits of networking. Get introduced to
                      networking tips and tools. Learn about common networking
                      mistakes. Practice your elevator pitch.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(projectsStyle)(SectionUniversityProductEmployability);
