import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.jsx";

function SectionTeam(props) {
  const { classes } = props;
  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Hello from us!</h2>
          <h5 className={classes.description}>
            Curious about who some of our presenters are? Here is a little about
            us...
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/aboutus_natasha_munasinghe.jpeg"
                  alt="FRANK Team Natasha Munasinghe"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Natasha Munasinghe</h4>
              <h6 className={classes.textMuted}>CEO / Presenter</h6>
              <p className={classes.cardDescription}>
                Disappointed her parents when she chose not pursue a legal
                career. Confused them even further when she learnt how to become
                a Software Developer. Luckily, developed a passion for
                Education. Very susceptible to good coffee.
              </p>
            </CardBody>
            <CardFooter className={classes.justifyContent}>
              <Button href="#pablo" justIcon simple color="linkedin">
                <i className="fab fa-linkedin-in" />
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/aboutus_amie_morris.jpeg"
                  alt=" FRANK Team Amie Morris"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Amie Morris</h4>
              <h6 className={classes.textMuted}>Presenter</h6>
              <p className={classes.cardDescription}>
                Could write a volume of books from her time training politicians
                about how to present and communicate with the media. Has
                ridiculously good posture. Her side hustle is called
                @Projectshine which is about teaching young people confidence.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/aboutus_melissa_abugazaleh.jpeg"
                  alt="FRANK Team Melissa Abu Gazaleh"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Melissa Abu Gazaleh</h4>
              <h6 className={classes.textMuted}>Presenter</h6>
              <p className={classes.cardDescription}>
                Has won numerous awards including EY Social Entrepreneur of the
                Year & NSW Young Australian of the Year. Incredibly passionate
                about men's mental health and runs Top Blokes Foundation. Yet
                insanely humble.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/aboutus_aaronngan.jpeg"
                  alt="FRANK Team Aaron Ngan"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Aaron Ngan</h4>
              <h6 className={classes.textMuted}>Presenter</h6>
              <p className={classes.cardDescription}>
                Cannot walk down the street without meeting someone he knows.
                Has commiited his life to enabling young people becoming
                successful entrepreneurs & creating their own futures; check out
                one of his ventures; @juniorachievementaustralia.
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/aboutus_malika_krishnadasan.jpeg"
                  alt="FRANK Team Malika Krishnadasan"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Malika Krishnadasan</h4>
              <h6 className={classes.textMuted}>Presenter</h6>
              <p className={classes.cardDescription}>
                Carved a remarkable and diverse career for herself from finance
                to change management working for companies like Microsoft,
                Medtronic and Canon Australia. Passionate about leadership and
                ensuring people work to their potential
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/aboutus_sachakaluri.jpeg"
                  alt=" FRANK Team Sacha Kaluri"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Sacha Kaluri</h4>
              <h6 className={classes.textMuted}>Presenter</h6>
              <p className={classes.cardDescription}>
                Has a store of energy that is hard to believe. From podcasts to
                TV and radio to presentng - Sacha is doing it all. Her passion
                is squarely on enabling young people to be their best.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/aboutus_ivan+chiew.jpeg"
                  alt="FRANK Team Ivan Chew"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Ivan Chew</h4>
              <h6 className={classes.textMuted}>Presenter</h6>
              <p className={classes.cardDescription}>
                Is studying his 5th degree - from construction management to
                psychology to stand up comedy - Ivan has you covered. There is
                nothing boring that takes place during his shift!
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/about+us_silvia+deredder.jpg"
                  alt="FRANK Team Silvia de Redder"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Silver de Redder</h4>
              <h6 className={classes.textMuted}>Presenter</h6>
              <p className={classes.cardDescription}>
                Lives and breathes neuroscience. Her past experience as an
                engineer ensures she brings a very science based foundation to
                training and learning experiences.
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(teamStyle)(SectionTeam);
