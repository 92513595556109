import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GroupWork from "@material-ui/icons/GroupWork";
import Extension from "@material-ui/icons/Extension";
import WatchLater from "@material-ui/icons/WatchLater";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.jsx";

function SectionLocalGovernmentFeatures({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto}`}
            >
              <h2
                className={`${classes.title} ${classes.mlAuto} ${classes.mrAuto}`}
              >
                Youth Programs. Youth Strategy. Program Management.
              </h2>
              <h5 className={classes.description}>
                We partner with local governments to deliver youth focused
                training as well as youth consultations and strategy services.
                Our programs deliver engagement every single time.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Chat}
                title="Training for Young People & Youth Programs"
                description="Empower your young people for the 21st century reality of work, study and play. We deliver engaging youth training for youth councils,
                reference groups, youth groups and youth committees"
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={VerifiedUser}
                title="Outsource Program Management"
                description="Effectively outsource the day-to-day management of your programs such
                as school holiday and youth programs to us. You then free up your valuable time to focus on your core job role helping young people."
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Fingerprint}
                title="Youth Strategy Services"
                description="We design and deliver innovative youth strategy and
                consultation services with young people, service providers
                and stakeholders"
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 1 END */}

        {/* Feature 3 START */}
        <div className={classes.features3}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
            >
              <h2 className={classes.title}>What we do for Councils?</h2>
            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
              <InfoArea
                className={classes.infoArea}
                icon={Extension}
                title="Comprehensive Face-to-Face Youth Training"
                description="We cover a wide range of training topics from event management, public speaking, leadership to entrepreneurship & innovation. We can customise the learning experience for your participants"
                iconColor="info"
              />
              <InfoArea
                className={classes.infoArea}
                icon={GroupWork}
                title="Staff Training on Youth Engagement"
                description="We deliver staff training to ensure you are able to communicate with and understand your young people and the emerging generations."
                iconColor="info"
              />
              <InfoArea
                className={classes.infoArea}
                icon={WatchLater}
                title="Youth Focused Strategy and Action Planning"
                description="We use the latest technology tools to co-create youth strategies, action plans and discussion papers with young people and Council staff and community"
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <InfoArea
                className={classes.infoArea}
                icon={Extension}
                title="Engaging Youth Forums"
                description="FRANK designs and delivers informative, engaging and youth forums to ensure you are able to consult with and hear dirctly from young people about issues that impact them. "
                iconColor="info"
              />
              <InfoArea
                className={classes.infoArea}
                icon={GroupWork}
                title="All Audiences"
                description="Whether your audience is 10 or a 200 participants; we have the expertise to build engaging learning experiences"
                iconColor="info"
              />
              <InfoArea
                className={classes.infoArea}
                icon={WatchLater}
                title="Engaging Online Video Training"
                description="We are busy building some new and exciting online video training options to help build the capacity of your youth and community. Stay tuned for updates..."
                iconColor="info"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withStyles(featuresStyle)(SectionLocalGovernmentFeatures);
