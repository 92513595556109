import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GroupWork from "@material-ui/icons/GroupWork";
import Extension from "@material-ui/icons/Extension";
import WatchLater from "@material-ui/icons/WatchLater";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.jsx";

function SectionOrientationFeatures({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto}`}
            >
              <h2 className={classes.title}>
                Australian Catholic University Orientations
              </h2>
              <h5 className={classes.description}>
                We worked with ACU campuses in Strathfield NSW, North Sydney NSW
                and Melbourne to deliver engaging icebreaker activities during
                Orientation Week.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Chat}
                title="Targetted & Engaged Orientation events for a 10-1000 person audience"
                description="The time of long speeches and Powerpoint presentations is over. We get your messaging to the audience in a way they understand"
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={VerifiedUser}
                title="Trusted by over 13 Universities"
                description="Our experience in the higher education sector allows us to deliver the best training programs and insights"
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Fingerprint}
                title="Integrated social media, games & AV activities"
                description="We know how to get your social media pages buzzing from Day 1."
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 1 END */}

        {/* Feature 3 START */}
        <div className={classes.features3}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto} ${
                classes.textCenter
              }`}
            >
              <h2 className={classes.title}>
                What we delivered for Australian Catholic University?
              </h2>
            </GridItem>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <InfoArea
                  className={classes.infoArea}
                  icon={Extension}
                  title="Icebreakers & Networking"
                  description="ACU wanted a short, sharp icebreaker and networking session to ensure new students could make friends and build networks at University."
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <InfoArea
                  className={classes.infoArea}
                  icon={GroupWork}
                  title="Flexibility & Agility"
                  description="Our facilitators have the experience and expertise to deliver sessions which respond to changing audience needs on the ground."
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <InfoArea
                  className={classes.infoArea}
                  icon={WatchLater}
                  title="Engagement at Every Level"
                  description="We always ensure that engagement is at the core of what we do; and this means we include activities, prizes and teambuilding games to help build rapport amongst audience participants"
                  iconColor="info"
                />
              </GridItem>
            </GridContainer>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withStyles(featuresStyle)(SectionOrientationFeatures);
