import React from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Favorite from "@material-ui/icons/Favorite";
import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";

import image from "assets/img/bg7.jpg";
import { getShema } from '../../schema/user';
import * as userApi from '../../api/user';
import { store } from 'react-notifications-component';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: {}
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  async validateInput(value, field) {
    const schema = getShema(field);
    const fieldName = field[0]
    try {
      await schema.validate({ [fieldName]: value })
      this.setState({ [fieldName]: value })
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          [fieldName]: null
        }
      }))
    } catch (err) {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          [fieldName]: err.errors[0]
        }
      }))
    }
  }
  async handleLogin() {
    const user = {
      email: this.state.email,
      password: this.state.password
    }

    try {
      const token = await userApi.login(user);
      localStorage.setItem('token', token)
      this.props.history.push({ pathname: "/" })
    } catch (err) {
      const errorMsg = err.response.data ? err.response.data : 'Fail To Login, Please Try Again Later';
      const error = { ...this.state.error }
      error.login = errorMsg
      this.setState({ error })
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="FRANK Education"
          links={<HeaderLinks dropdownHoverColor="info" />}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundColor: "#00acc1",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  {this.state.error.login ?
                    <div className={classes.cardError}> <h5 className={classes.cardErrorTitle}>{this.state.error.login}</h5></div>
                    : null
                  }
                  <form className={classes.form}>
                    {/* <CardHeader
                      color="primary"
                      signup
                      className={classes.cardHeader}
                    >
                      <h4 className={classes.cardTitle}>Login</h4>
                      <div className={classes.socialLine}>
                        <Button
                          justIcon
                          color="transparent"
                          className={classes.iconButtons}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-twitter" />
                        </Button>
                        <Button
                          justIcon
                          color="transparent"
                          className={classes.iconButtons}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-facebook" />
                        </Button>
                        <Button
                          justIcon
                          color="transparent"
                          className={classes.iconButtons}
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fab fa-google-plus-g" />
                        </Button>
                      </div>
                    </CardHeader>
                    <p
                      className={`${classes.description} ${classes.textCenter}`}
                    >
                      Or Be Classical
                    </p> */}
                    <CardBody signup>
                      <CustomInput
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder: "Email...",
                          type: "email",
                          onChange: (event) => this.validateInput(event.target.value, ['email']),
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        error={this.state.error.email || null}
                      />
                      {this.state.error.email ?
                        <p className={classes.fontError}>{this.state.error.email}</p> :
                        null}
                      <CustomInput
                        id="pass"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder: "Password",
                          type: "password",
                          onChange: (event) => this.validateInput(event.target.value, ['password']),
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
                          )
                        }}
                        error={this.state.error.password || null}
                      />
                      {this.state.error.password ?
                        <p className={classes.fontError}>{this.state.error.password}</p> :
                        null}
                    </CardBody>
                    <div className={classes.textCenter}>
                      <Button round color="customButtonColor" size="md" onClick={this.handleLogin.bind(this)}>
                        Login
                      </Button>
                    </div>
                  </form>
                  <div className={classes.textCenter}>
                    <span >
                      Looking to{" "}
                      <Link to="/signup-page"> create an account </Link>
                    </span>
                  </div>
                  <div className={classes.textCenter}>
                    <span >
                      <Link to="/forget-password"> Forget password?</Link>
                    </span>
                  </div>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer
            className={classes.footer}
            content={
              <div>
                <div className={classes.left}>
                  <List className={classes.list}>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/about-us" className={classes.block}>
                        About us
                      </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/universities" className={classes.block}>
                        Universities
                      </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/lga" className={classes.block}>
                        Local Governments
                      </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/courses" className={classes.block}>
                        Courses
                      </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/contact-us" className={classes.block}>
                        Contact Us
                      </Link>
                    </ListItem>
                  </List>
                </div>
                <div className={classes.right}>
                  &copy; {1900 + new Date().getYear()}
                  {""}. Made with <Favorite className={classes.icon} /> by FRANK
                  Education
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default withStyles(loginPageStyle)(LoginPage);
