import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GroupWork from "@material-ui/icons/GroupWork";
import Extension from "@material-ui/icons/Extension";
import WatchLater from "@material-ui/icons/WatchLater";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.jsx";

function SectionLocalGovernmentRydeStrategyFeatures({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto}`}
            >
              <h2 className={classes.title}>
                City of Ryde Council Youth Strategy and Action Plan
              </h2>
              <h5 className={classes.description}>
                FRANK worked with Ryde Council to deliver their 4 year Youth
                Strategy (Discussion Paper) and to create a detailed Priority
                Action Plan to ensure responsive service delivery.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Chat}
                title="Face-to-Face and Offline Consultations"
                description="FRANK consulted young people, service providers, Council staff and critical stakeholders through focus groups, pop-up stalls in high traffic locations, surveys at youth events, service provider and Councillor workshops as well as face-to-face and phone consultations."
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={VerifiedUser}
                title="Online Consultations & Surveys"
                description="FRANK worked in collaboration with Council's communications teams to learn and apply best practice survey methodologies and created surveys for both young people and service providers."
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Fingerprint}
                title="Data Analysis, Reporting & Design"
                description="FRANK ensured all the relevant data was captured and recorded accurately and effectively. We also ensured that we designed a document that made it easy for people (including young people) to understand the insights. Clear, simple and visual communication is very important to us."
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>

        <div className={classes.features3}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
            >
              <h2 className={classes.title}>
                What FRANK delivered for Ryde Council?
              </h2>
            </GridItem>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <InfoArea
                  className={classes.infoArea}
                  icon={Extension}
                  title="Ryde Strategic Discussion Paper"
                  description={
                    <span>
                      FRANK designed and delivered a Discussion Paper to guide
                      Ryde Council when delivering services for young people in
                      the LGA. This Paper focused on 6 key priority areas for
                      young people. It also identified a snapshot of what 'young
                      Ryde' looks like. A further and detailed Priority Action
                      Plan document was created from the findings of this Paper.
                      <br />
                      <a
                        href="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/FT_COR_YouthStrategy-DiscussionPaper_FA_PRINT-min.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read the Discussion Paper{" "}
                      </a>
                    </span>
                  }
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <InfoArea
                  className={classes.infoArea}
                  icon={GroupWork}
                  title="Ryde 4 Year Priority Action Plan"
                  description="This Priority Action Plan is an internal document for Ryde Council to ensure accountability and delivery of services over 4 years. This Plan outlines specific Goals, Actions, Deadlines and Responsibilities for each of the 6 Key Priority Areas(i.e. Transport & Safety, Health & Wellbeing, Future Aspirations & Housing, Arts & Culture, Education & Employment and Sports & Recreation) outlined in the Strategic Discussion Paper."
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <InfoArea
                  className={classes.infoArea}
                  icon={WatchLater}
                  title="Engaging Ryde Strategy Video"
                  description={
                    <span>
                      Young people like to access content which is bite-sized so
                      FRANK created a short, sharp video for young people in the
                      LGA which informs them of Ryde's strategic priorities.
                      FRANK in collaboration with Ryde's Youth Advisory Group
                      created a strategy hashtag for the consultations so people
                      could share their ideas using the hashtag.{" "}
                      <a
                        href="https://www.youtube.com/watch?v=x61-BuKKQPw&feature=youtu.be"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Watch the video{" "}
                      </a>
                    </span>
                  }
                  iconColor="info"
                />
              </GridItem>
            </GridContainer>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withStyles(featuresStyle)(
  SectionLocalGovernmentRydeStrategyFeatures
);
