import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GroupWork from "@material-ui/icons/GroupWork";
import Extension from "@material-ui/icons/Extension";
import WatchLater from "@material-ui/icons/WatchLater";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.jsx";

function SectionLocalGovernmentDarwinStrategyFeatures({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto}`}
            >
              <h2 className={classes.title}>
                City of Darwin Council Youth Strategy
              </h2>
              <h5 className={classes.description}>
                We worked with Darwin Council to deliver community, service
                provider and youth consultations and focus groups to explore key
                insights gained from the Darwin Youth Survey.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Chat}
                title="Face-to-Face and Offline Consultations"
                description="We consulted young people, service providers, Council staff and critical stakeholders through focus groups, pop-up stalls in high traffic locations, surveys at youth events, service provider and Councillor workshops as well as face-to-face and phone consultations."
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={VerifiedUser}
                title="Data Analysis"
                description="We researched and identified key insights across the 6 key themes identified in the Darwin Youth Survey."
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Fingerprint}
                title="Draft Youth Strategy Reporting"
                description="We compiled a draft Youth Strategy report to Council communicating our main findings and key areas for action."
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 1 END */}

        {/* Feature 3 START */}
        <div className={classes.features3}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
            >
              <h2 className={classes.title}>
                What we delivered for Darwin Council?
              </h2>
            </GridItem>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <InfoArea
                  className={classes.infoArea}
                  icon={Extension}
                  title="Darwin Community Consultations"
                  description="FRANK delivered 4 focus groups on topics such as Body
                      Image, Alcohol/Drugs, Transport and Study/Pressure of
                      Exams. In these focus groups, we reviewed the previous
                      Youth Strategy to identify key lessons learnt from the
                      previous Youth Strategy, discussed current infrastructure
                      and service delivery offerings,explored potential new ways
                      of service delivery to address community needs and looked
                      at increasing collaboration in the sector."
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <InfoArea
                  className={classes.infoArea}
                  icon={GroupWork}
                  title="Darwin Council Youth Strategy"
                  description={
                    <span>
                      FRANK worked with Council to identify key findings for 6
                      Key Priority Areas(i.e. Transport & Safety, Health &
                      Wellbeing, Access to Information, Arts & Culture,
                      Education & Employment and Sports & Recreation) outlined
                      in the Strategic Discussion Paper.
                      <br />
                      <a
                        href="https://www.darwin.nt.gov.au/sites/default/files/publications/attachments/young_darwin_-_full_version.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read Darwin Youth Strategy
                      </a>
                    </span>
                  }
                  iconColor="info"
                />
              </GridItem>
            </GridContainer>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withStyles(featuresStyle)(
  SectionLocalGovernmentDarwinStrategyFeatures
);
