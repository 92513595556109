import React from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Favorite from "@material-ui/icons/Favorite";
import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import resetPasswordPagStyle from "assets/jss/material-kit-pro-react/views/resetPasswordPagStyle.jsx";

import image from "assets/img/bg7.jpg";
import { getShema } from '../../schema/user';
import * as userApi from '../../api/user';
import { store } from 'react-notifications-component';

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      error: {}
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  async validatePassword(value) {
    const schema = getShema("password");
    try {
      await schema.validate({ password: value })
      this.setState({ password: value })
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          password: null
        }
      }))
    } catch (err) {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          password: err.errors[0]
        }
      }))
    }
  }
  validateConfirmPassword(value) {
    if (this.state.password === value)
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          confirmed: null
        }
      }))
    else
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          confirmed: 'Passwords doesn`t matched'
        }
      }))
  }
  async handleReset() {
    const data = {
      newPassword: this.state.password
    }
    const token = this.props.match.params.token
    try {
      await userApi.resetPassword(data, token);
      this.props.history.push({ pathname: "/login-page" })
    } catch (err) {
      const errorMsg = err.response.data ? err.response.data : 'Fail To Reset Password, Please Try Again Later';
      const error = { ...this.state.error }
      error.reset = errorMsg
      this.setState({ error })
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="FRANK Education"
          links={<HeaderLinks dropdownHoverColor="info" />}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundColor: "#00acc1",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={10} md={8}>
                <Card>
                  <h2 className={classes.cardTitle}>Enter a new password</h2>
                  {this.state.error.reset ?
                    <div className={classes.cardError}>
                      <h5 className={classes.cardErrorTitle}>{this.state.error.reset}</h5>
                    </div>
                    : null
                  }


                  <CardBody signup>
                    <GridContainer justify="center">

                      <form className={classes.form}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                  </Icon>
                              </InputAdornment>
                            ),
                            type: "password",
                            placeholder: "Password...",
                            onChange: (event) => this.validatePassword(event.target.value)
                          }}
                          error={this.state.error.password || null}
                        />
                        {this.state.error.password ?
                          <p className={classes.fontError}>{this.state.error.password}</p> :
                          null}
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                  </Icon>
                              </InputAdornment>
                            ),
                            type: "password",
                            placeholder: "Confirm password...",
                            onChange: (event) => this.validateConfirmPassword(event.target.value)
                          }}
                          error={this.state.error.confirmed || null}
                        />
                           {this.state.error.confirmed ?
                          <p className={classes.fontError}>{this.state.error.confirmed}</p> :
                          null}
                      </form>

                    </GridContainer>
                    <div className={classes.textCenter}>
                      <Button round color="customButtonColor" size="sm" onClick={this.handleReset.bind(this)}>
                        Reset
                      </Button>
                    </div>
                  </CardBody>


                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer
            className={classes.footer}
            content={
              <div>
                <div className={classes.left}>
                  <List className={classes.list}>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/about-us" className={classes.block}>
                        About us
                      </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/universities" className={classes.block}>
                        Universities
                      </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/lga" className={classes.block}>
                        Local Governments
                      </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/courses" className={classes.block}>
                        Courses
                      </Link>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <Link to="/contact-us" className={classes.block}>
                        Contact Us
                      </Link>
                    </ListItem>
                  </List>
                </div>
                <div className={classes.right}>
                  &copy; {1900 + new Date().getYear()}
                  {""}. Made with <Favorite className={classes.icon} /> by FRANK
                  Education
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default withStyles(resetPasswordPagStyle)(ResetPasswordPage);
