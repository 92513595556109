/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";

class SectionUniversityProductOrientations extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className="cd-section" {...rest}>
        <div className={classes.projects}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
              >
                <h2 className={classes.title}>
                  You may also be interested in..
                </h2>
                <h5 className={classes.description}>
                  Who says you can't engage 1000 people at once? Not us!
                </h5>
                <div className={classes.sectionSpace} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/orientation-uts-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_orientations_uts-min.jpeg"
                        alt="University of Technology Sydney Orientation"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/orientation-uts-page">
                      <h4 className={classes.cardTitle}>
                        Case Study 1: University of Technology Sydney
                        Orientation
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      Engaging & Interactive Large Events
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      We designed and continue to deliver the main orientation
                      sessions @ UTS for all incoming students, in collaboration
                      with UTS Careers. The sessions are interactive, engaging
                      and utilises social media activities to deliver key
                      messaging to the audience.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/orientation-wsu-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_orientations_wsu.jpeg"
                        alt="University of Western Sydney Orientation"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/orientation-wsu-page">
                      <h4 className={classes.cardTitle}>
                        Case Study 2: University of Western Sydney Orientation
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      {" "}
                      Engaging & Interactive Large Events
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      This orientation is designed and delivered for over 1000
                      international & Study Abroad and Exchange students each
                      semester. It introduces messages safety on campus, future
                      of work and work rights and much more - in a fun and
                      engaging format.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/orientation-acu-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/universityPage_ACUOrientation.JPG"
                        alt="Australian Catholic University Orientation"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/orientation-acu-page">
                      <h4 className={classes.cardTitle}>
                        Case Study 3: Australian Catholic University Orientation
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      Engaging & Interactive Large Events
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      We were engaged to deliver to short, sharp icebreaker and
                      networking activities across multiple ACU campuses for
                      their student orientations.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(projectsStyle)(SectionUniversityProductOrientations);
