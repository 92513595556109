import {
    primaryColor,
    container,
    description,
    cardTitle,
    blackColor,
    whiteColor,
    grayColor,
    hexToRgb,
    dangerColor
  } from "assets/jss/material-kit-pro-react.jsx";
  
  const signupPageStyle = theme => ({
    description,
    cardTitle: {
      ...cardTitle,
      textDecoration: "none",
      textAlign: "center !important",
      marginBottom: "0.75rem"
    },
    container: {
      ...container,
      zIndex: "2",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "100px"
      }
    },
    cardError: {
      ...cardTitle,
      textDecoration: "none",
      textAlign: "center !important",
      marginBottom: "0.75rem",
      // color: dangerColor[3],
      background:dangerColor[3],
      width: "300px",
      margin: "200px"
    },
    cardErrorTitle:{
      color: whiteColor
    },
    fontError: {
      color: dangerColor[3]
    },
    pageHeader: {
      color: whiteColor,
      border: "0",
      height: "100%",
      margin: "0",
      display: "flex!important",
      padding: "120px 0",
      position: "relative",
      minHeight: "100vh",
      alignItems: "center",
      "&:before": {
        background: primaryColor
      },
      "&:before,&:after": {
        position: "absolute",
        zIndex: "1",
        width: "100%",
        height: "100%",
        display: "block",
        left: "0",
        top: "0",
        content: '""'
      }
    },
    form: {
      margin: "0"
    },
    cardHeader: {
      width: "auto",
      textAlign: "center"
    },
    socialLine: {
      marginTop: "1rem",
      textAlign: "center",
      padding: "0"
    },
    inputIconsColor: {
      color: grayColor[13]
    },
    textCenter: {
      textAlign: "center"
    },
    iconButtons: {
      marginRight: "3px !important",
      marginLeft: "3px !important"
    },
    block: {
      color: "inherit",
      padding: "0.9375rem",
      fontWeight: "500",
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "3px",
      textDecoration: "none",
      position: "relative",
      display: "block"
    },
    inlineBlock: {
      display: "inline-block",
      padding: "0px",
      width: "auto"
    },
    list: {
      marginBottom: "0",
      padding: "0",
      marginTop: "0"
    },
    left: {
      float: "left!important",
      display: "block",
      "&,& *,& *:hover,& *:focus": {
        color: whiteColor + "  !important"
      }
    },
    right: {
      padding: "15px 0",
      margin: "0",
      float: "right",
      "&,& *,& *:hover,& *:focus": {
        color: whiteColor + "  !important"
      }
    },
    icon: {
      width: "18px",
      height: "18px",
      top: "3px",
      position: "relative"
    },
    footer: {
      position: "absolute",
      width: "100%",
      background: "transparent",
      bottom: "0",
      color: whiteColor,
      zIndex: "2"
    }
  });
  
  export default signupPageStyle;
  