import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GroupWork from "@material-ui/icons/GroupWork";
import Extension from "@material-ui/icons/Extension";
import WatchLater from "@material-ui/icons/WatchLater";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.jsx";

function SectionOrientationFeatures({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto}`}
            >
              <h2 className={classes.title}>
                Western Sydney University International Orientation
              </h2>
              <h5 className={classes.description}>
                FRANK has designed and delivered a very well-received
                informative yet inspiring welcome session for new international
                students.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Chat}
                title="Targetted & Engaged Orientation events for a 10-1000 person audience"
                description="The time of long speeches and Powerpoint presentations is over. We get your messaging to the audience in a way they understand"
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={VerifiedUser}
                title="Trusted by over 13 Universities"
                description="Our experience in the higher education sector allows us to deliver the best training programs and insights"
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Fingerprint}
                title="Integrated social media, games & AV activities"
                description="We know how to get your social media pages buzzing from Day 1."
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 1 END */}

        {/* Feature 3 START */}
        <div className={classes.features3}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
            >
              <h2 className={classes.title}>
                What FRANK did for University of Western Sydney?
              </h2>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <div className={classes.phoneContainer}>
                <img
                  src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_orientations_wsu.jpeg"
                  alt="FRANK Team WSU Orientation"
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <InfoArea
                className={classes.infoArea}
                icon={Extension}
                title="Delivered on WSU Outcomes"
                description="WSU wanted to inspire & welcome newly arrived international and study-abroad and exchange students and introduce them to student and careers services as well as inform them on issues such as work and visa rights. So FRANK designed a 2 hour program that delivers these key messages in an engaging and informative manner. A FRANK Presenter also delivers this session along with key WSU staff speakers."
                iconColor="info"
              />
              <InfoArea
                className={classes.infoArea}
                icon={GroupWork}
                title="All Audiences"
                description="Whether your audience is 10 or a 1000 participants; FRANK has the expertise to build engaging learning experiences. FRANK understands the unique needs of international students."
                iconColor="info"
              />
              <InfoArea
                className={classes.infoArea}
                icon={WatchLater}
                title="Leverage Social Media"
                description="FRANK integrated University Facebook activities into the program to ensure that the University creates future communication opportunities with these students after Orientation. WSU gets more than 500+ likes after each Orientation session."
                iconColor="info"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withStyles(featuresStyle)(SectionOrientationFeatures);
