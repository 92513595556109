import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.jsx";

function SectionTestimonialsOrientation({ ...props }) {
  const { classes, ...rest } = props;

  return (
    <div className="cd-section" {...rest}>
      {/* Testimonials 1 START */}
      <div
        className={`${classes.testimonials} ${classes.sectionImage}`}
        style={{
          backgroundImage: `url("https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/testimonials_background.jpg")`
        }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={`${classes.mlAuto} ${classes.mrAuto} ${
                classes.textCenter
              }`}
            >
              <h2 className={classes.title}>Client Love</h2>
              <h5 className={classes.description}>
                You are our priority. Here is what a few of our clients have
                said...
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    FRANK were very professional leading up to and throughout
                    the workshop. I found the facilitator to be very warm and
                    likeable, which is important to engage the students. I
                    received very favourable feedback from the participants in
                    the workshop - it seemed to open their minds to options and
                    ideas they hadn't previously considered and gave them
                    practical skills they could utilise in their future
                    networking situations.
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Kaye Harrison</h4>
                  <h6 className={classes.cardCategory}>Macquarie University</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/testimonials_university_macquarie.png"
                        alt="..."
                      />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    Natasha and her team are amazing and understand completely
                    what our business necessities are.
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Eduardo Carvallo</h4>
                  <h6 className={classes.cardCategory}>
                    Dept of Education NSW Government
                  </h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/testimonials_govt_deptofeducation.png"
                        alt="..."
                      />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    I can recommend FRANK for any project where enthusiasm,
                    passion and results are required. We received excellent
                    feedback from the Deputy Vice Chancellor about the seminar."
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Nicole Woll</h4>
                  <h6 className={classes.cardCategory}>
                    University of Technology Sydney
                  </h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/testimonials_university_uts.jpeg"
                        alt="..."
                      />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 1 END */}
    </div>
  );
}

export default withStyles(testimonialsStyle)(SectionTestimonialsOrientation);
