/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";

class SectionProductNatasha extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className="cd-section" {...rest}>
        <div className={classes.projects}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
              >
                <h2 className={classes.title}>Popular Courses </h2>
                <h5 className={classes.description}>
                  Here are our bestseller, change-your-life courses that clients
                  and participants love
                </h5>
                <div className={classes.sectionSpace} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/communication-strategies-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_CommunicStrategies.jpg"
                        alt="Communication Strategies course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/communication-strategies-page">
                      <h4 className={classes.cardTitle}>
                        Master your Communication Skills
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      You learn what communication is. You develop your
                      listening skills. You identfiy what communication barriers
                      are and you learn how to ask great questions
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/civic-leadership-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_civic_leadership-min.jpg"
                        alt="Civic Leadership Design Thinking Course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/civic-leadership-page">
                      <h4 className={classes.cardTitle}>
                        Civic Leadership & Design Thinking
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      {" "}
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      This course will enable you to action your social ideas in
                      the true spirit of civic leadership. You apply key design
                      thinking skills in a design sprint including ideation. You
                      complete the workshop with an exciting pitch of your idea.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card plain className={classes.card2}>
                  <Link to="/product-page/presentation-skills-page">
                    <CardHeader image plain>
                      <img
                        src="https://frankteamwebsite.s3-ap-southeast-2.amazonaws.com/programs_presentation-min.jpeg"
                        alt="Presentation Skills course"
                      />
                    </CardHeader>
                  </Link>
                  <CardBody plain>
                    <Link to="/product-page/presentation-skills-page">
                      <h4 className={classes.cardTitle}>
                        Present like a TED Speaker
                      </h4>
                    </Link>
                    <h6 className={classes.description}>
                      Engaging Face-to-Face Training
                    </h6>
                    <p
                      className={`${classes.description} ${classes.marginTop20}`}
                    >
                      Presentation doesn't come easy to anyone. You need to know
                      the strategies and tips that the experts use. Let us show
                      you how - we don't teach you just the theory - there is
                      plenty of practice.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(projectsStyle)(SectionProductNatasha);
